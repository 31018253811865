  const logo = document.querySelector('.logo');
  window.addEventListener('scroll', function(e) {
    logo && logo.classList[this.scrollY > 100 ? 'add' : 'remove']('logo--smaller');
  });

  const navbar = document.querySelector('.sticky-header');
  const hamburger = document.querySelector('.hamburger');
  hamburger && navbar && hamburger.addEventListener('click', function(e) {
    navbar.classList.toggle('sticky-header--mobile-active');
  });
